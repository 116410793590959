import Vue from 'vue'
import App from './App.vue'
import createRouter from './router'
import store from './store'
import permission from '@/utils/permission'
import './ui'
import './global.jsx'

const vueOptions = {
  el: 'body',
  store,
  render: h => h(App),
}
Vue.directive('permission', permission)

window.root = new Vue({
  ...vueOptions,
  router: createRouter(),
  el: '#app',
})
