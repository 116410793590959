export default [
  {
    /**
     * 查询组
     */
    name: 'queryOwnerList',
    method: 'post',
    url: '/api/owner/queryList',
  },
  {
    /**
     * 查询组
     */
    name: 'queryAllOwner',
    method: 'post',
    url: '/api/owner/queryAll',
  },
  {
    /**
     * 查询已选择的menu
     */
    name: 'queryMenuIdsByOwner',
    method: 'post',
    url: '/api/owner/queryMenuIdsByOwnerId',
  },
  {
    /**
     * 查询已选择的menu
     */
    name: 'queryMenusByOwner',
    method: 'post',
    url: '/api/owner/queryMenusByOwnerId',
  },
  {
    /**
     * 保存OwnerFunction关系
     */
    name: 'saveOwnerMenus',
    method: 'post',
    url: '/api/owner/saveOwnerMenus',
  },
  {
    /**
     * 保存OwnerFunction关系
     */
    name: 'saveOwnerSites',
    method: 'post',
    url: '/api/owner/saveOwnerSites',
  },
]
