<template>
  <el-container class="main" direction="vertical">
    <el-header v-if="!['login', 'find', 'reset'].includes($route.name)" class="header bg-white" height="80px">
      <div class="w-full mx-auto flex items-center">
        <router-link class="text-28px text-primary text-opacity-75 font-arial font-bold" :to="{ path: '/' }">
          <img class="h-48px mr-16px" src="./assets/images/logo2.png" />
        </router-link>
        <div class="flex-1 text-right text-black text-opacity-85">
          <template v-if="userInfo.isLogined">
            <el-dropdown @command="handleChange">
              <div class="flex items-center">
                <img class="w-24px h-24px face" />
                <span class="mx-8px cursor-pointer relative text-black text-opacity-85">
                  Hello {{ userInfo.userName }}
                </span>
                <i class="el-icon-arrow-down el-icon--right relative right-3px text-black text-opacity-85"></i>
              </div>
              <el-dropdown-menu slot="dropdown" class="head-dropdown min-w-160px">
                <el-dropdown-item command="password"> Change Password </el-dropdown-item>
                <el-dropdown-item command="exit"> Logout </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </div>
      </div>
    </el-header>
    <router-view></router-view>
  </el-container>
</template>
<script lang="jsx">
import service from './service'
import store from './store'
import Change from '@/views/user/component/Change.vue'
export default {
  data() {
    return {
      visible: false,
    }
  },
  methods: {
    async handleLogout() {
      await service.loginOut().executeSerial()

      await store.dispatch('user/updateUser', { isLogined: undefined })

      this.$router.push({ name: 'login' })
    },
    async handleChange(cmd) {
      if (cmd === 'password') {
        this.openDialog(<Change></Change>, { title: 'Reset Password' })
      } else if (cmd === 'exit') {
        await this.handleLogout()
      }
    },
  },
}
</script>
<style lang="less">
.main {
  min-height: 100vh;
}

.header {
  display: flex;
  align-items: center;
  height: 56px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.02), 0 2px 12px 0 rgba(0, 0, 0, 0.04), 0 2px 6px 0 rgba(0, 0, 0, 0.02);
  z-index: 100;

  .logo {
    width: 180px;
    height: 48px;
    margin-right: 48px;
  }

  &-right-font {
    &-divide {
      display: inline-block;
      width: 1px;
      height: 12px;
      background: rgba(229, 229, 229, 1);
      margin: 0 16px 0 16px;
    }

    &-logout {
      cursor: pointer;
      color: @primary-color;
    }
  }
}

.cookie-prompt.el-notification {
  padding: 0;
  .el-notification__group {
    margin: 12px;
  }
}
</style>
