export default [
  {
    /**
     * 查询
     */
    name: 'querySiteList',
    method: 'post',
    url: '/api/site/queryList',
  },
  {
    /**
     * 查询分组站点
     */
    name: 'queryAllSiteGroupByOwner',
    method: 'post',
    url: '/api/site/queryAllSiteGroupByOwner',
  },
  {
    /**
     * 通过参数查询站点
     */
    name: 'queryAllSiteByParameter',
    method: 'post',
    url: '/api/site/queryAllSiteByParameter',
  },
  {
    /**
     * 查询所有站点
     */
    name: 'queryAllSite',
    method: 'post',
    url: '/api/site/queryAllSite',
  },
  {
    /**
     * 查询所有站点,不区分Owner
     */
    name: 'queryAllSites',
    method: 'post',
    url: '/api/site/queryAllSites',
  },
  {
    /**
     * 查询站点
     */
    name: 'getSite',
    method: 'post',
    url: '/api/site/getSite',
  },
  {
    /**
     * 查询kv
     */
    name: 'queryDateTypeValue',
    method: 'post',
    url: '/api/site/queryDateTypeValue',
  },
  {
    /**
     * 查询站点参数地图
     */
    name: 'querySiteParameterMenu',
    method: 'post',
    url: '/api/site/querySiteParameterMenu',
  },
  {
    /**
     * 查询站点参数地图
     */
    name: 'queryAllSiteByOwnerId',
    method: 'post',
    url: '/api/site/queryAllSiteByOwnerId',
  },
]
