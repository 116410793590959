const state = {
  userInfo: {},
  menus: [],
  permission: {},
  allTree: [],
}

const mutations = {
  UPDATE_USER: (state, userInfo) => {
    state.userInfo = userInfo
  },
  UPDATE_MENU: (state, menus) => {
    state.menus = menus
  },
  UPDATE_ALL_TREE: (state, tree) => {
    state.allTree = tree
  },
  UPDATE_PERMISSION: (state, permission) => {
    state.permission = permission
  },
}

const actions = {
  updateUser({ commit }, userInfo) {
    commit('UPDATE_USER', userInfo)
  },
  permission({ commit }, menus) {
    let newMenus = []
    let permission = {}
    const walk = (tree, newTree) => {
      tree.forEach(item => {
        var newItem = {
          title: item.name,
          icon: item.icon,
          link: item.url,
          type: item.type,
          id: item.id,
          children: [],
        }
        permission[item.code] = true
        newTree.push(newItem)

        if (item.children) {
          walk(item.children, newItem.children)
        }
      })
    }

    walk(menus, newMenus)

    commit(
      'UPDATE_MENU',
      newMenus.filter(item => item.type === '1'),
    )
    commit('UPDATE_ALL_TREE', newMenus)
    commit('UPDATE_PERMISSION', permission)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
